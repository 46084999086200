.hero-page-details-container {
  padding: 20px;
  margin-top: 40px;
}

.hero-page-details-text {
  color: #fff;
  font-family: "Be Vietnam Pro", sans-serif;
}

.hero-page-text-container > div > h3 {
  font-size: 32px;
  font-weight: 600;
  width: max-content;
  line-height: 150%;
}

.hero-page-text-container > div {
  overflow: hidden;
}

/* Loop through indices 2 to 3 */

@for $i from 2 through 3 {
  /* Select the div.heading */
  div.hero-pae-text-container > div {
    /* Set animation delay */
    animation-delay: 0;
    /* Select the nth child based on $i */
    &:nth-child(#{$i}) > p {
      /* Set animation delay based on $i */
      animation-delay: calc(#{$i} * 0.05s);
    }
  }
}

.slide-up {
  animation: slide-up 2s cubic-bezier(0.65, 0, 0.35, 1) both;
}

@keyframes slide-up {
  0% {
    transform: translateY(100px);
  }
  100% {
    transform: translateY(0px);
  }
}

.hero-page-text-container > div > h3 > span {
  font-size: 32px;
  font-weight: 600;
  color: #d5fc48;
}

.hero-page-details-text > h5 {
  margin-bottom: 15px;
  margin-top: 30px;
  font-size: 24px;
  font-weight: 400;
  /* animation: fade-up 3s; */
}

@keyframes fade-up {
  from {
    opacity: 0.5;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

.hero-page-details-text {
  width: 85%;
}

.hero-page-details-text > h5 > span {
  color: #d5fc48;
}

.hero-page-details-text > h3 {
  font-size: 32px;
  font-weight: 600;
}

.hero-page-details-text > h3 > span {
  color: #d5fc48;
}

@media (max-width: 600px) {
  .hero-page-details-text {
    width: 90%;
  }
  .hero-page-details-container {
    padding: 10px;
    margin-top: 0px;
  }
  .hero-page-text-container > div > h3 {
    font-size: 22px;
    width: 100%;
    line-height: 120%;
  }
  .hero-page-text-container > div > h3 > span {
    font-size: 22px;
  }
  .hero-page-details-text > h5 {
    font-size: 14px;
  }
}
