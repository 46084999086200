.cta-wrapper {
  padding: 30px;
  background: #fff;
}

.cta-container {
  background: #0f4c54;
  border-radius: 20px;
  padding: 50px;
  display: block;
  margin: auto;
  height: 420px;
  margin-top: 50px;
  position: relative;
}

.cta-details {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.cta-text > h5 {
  width: 50%;
  font-family: "Be Vietnam Pro", sans-serif;
  font-size: 30px;
  font-weight: 300;
  color: #fff;
}

.cta-text > h5 > span {
  color: #d5fc48;
  font-weight: 500;
}

.cta-illustration-image {
  position: absolute;
  right: 0;
  top: 0px;
  height: 100%;
}

.cta-illustration {
  height: 420px;
}

.connection-loader > img {
  width: 200px;
  position: absolute;
  right: 0;
  bottom: -20px;
}

@media (min-width: 1200px) {
  .cta-illustration-image-mobile {
    display: none;
  }
}

@media (max-width: 600px) {
  .cta-wrapper {
    padding: 15px;
  }
  .cta-container {
    padding: 20px;
    height: 750px;
  }
  .cta-text {
    width: 100%;
    margin-top: 40px;
  }
  .cta-text > h5 {
    width: 100%;
    font-size: 22px;
    text-align: center;
    font-weight: 500;
  }
  .cta-details {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .cta-illustration-image {
    display: none;
  }
  /* .cta-illustration {
    width: 80%;
  } */
  .cta-illustration-image-mobile {
    width: 390px;
    margin-top: 40px;
  }
  .connection-loader > img {
    width: 170px;
    left: 0;
  }
}
