.search-connection-wrapper {
  background: transparent;
  margin-top: 130px;
  padding: 30px;
  position: relative;
}

.search-connection-details {
  background: #eefdff;
  padding: 50px;
  border-radius: 20px;
  display: flex;
  gap: 100px;
  justify-content: center;
  align-items: center;
}

.search-connection-details-illustration {
  background: #0f4c54;
  border-radius: 20px;
  width: 450px;
  height: 580px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.search-connection-details-illustration > img {
  width: 400px;
}

.search-connection-text {
  width: 45%;
}

.connection-text {
  width: 50%;
}

.search-connection-text > h5 {
  font-family: "Be Vietnam Pro", sans-serif;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 15px;
  color: #4f4f4f;
}

.connection-text > h5 {
  font-family: "Be Vietnam Pro", sans-serif;
  font-weight: 500;
  font-size: 19px;
  margin-bottom: 15px;
  color: #4f4f4f;
}

.search-connection-text > h3 {
  font-family: "Be Vietnam Pro", sans-serif;
  font-weight: 400;
  font-size: 24px;
  margin-bottom: 15px;
  color: #333333;
}

.connection-text > h3 {
  font-family: "Be Vietnam Pro", sans-serif;
  font-weight: 400;
  font-size: 24px;
  margin-bottom: 15px;
  color: #333333;
}

/* .connections-wrappers {
  height: 100vh;
} */

.connection-text > h3 > span {
  color: #ec724a;
}

.connection-text > h3 > span:nth-child(2) {
  color: #16d2ea;
}

.search-connection-text > h3 > span {
  color: #0f4c54;
  font-size: 24px;
  font-weight: 600;
}

.bottom-image {
  position: absolute;
  bottom: 30px;
  right: 30px;
}

.connection.bottom-image {
  position: absolute;
  bottom: 30px;
  left: 30px;
}

.connection.bottom-image > img {
  width: 300px;
}

.bottom-image > img {
  width: 300px;
}

.connections-wrapper {
  margin-top: 20px;
  position: relative;
  padding: 30px;
  background: #ffff;
}

@media (max-width: 900px) {
  .search-connection-wrapper,
  .connections-wrapper {
    padding: 15px;
    margin-top: 60px;
  }
  .search-connection-details {
    flex-direction: column;
    gap: 25px;
    padding: 20px;
  }
  .search-connection-details-illustration {
    width: 335px;
    height: 350px;
  }
  .search-connection-details-illustration > img {
    width: 300px;
  }
  .connection-text,
  .search-connection-text {
    width: 100%;
  }
  .bottom-image {
    right: 20px;
    bottom: 10px;
  }
  .connection.bottom-image {
    bottom: 20px;
  }
  .connection.bottom-image > img,
  .bottom-image > img {
    width: 100px;
  }
  .connection-text > h5,
  .search-connection-text > h5 {
    font-size: 13px;
    font-weight: 600;
    margin-top: 30px;
  }
  .search-connection-text > h3,
  .connection-text > h3 {
    font-size: 18px;
  }
}
