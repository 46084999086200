.primary-buttons {
  display: flex;
  align-items: center;
  gap: 50px;
  margin-top: 40px;
}

.primary-buttons > button {
  background: #0f4c54;
  border: none;
  color: #f1f1f1;
  overflow: hidden;
  padding: 14px;
  font-family: "Be Vietnam Pro", sans-serif;
  border-radius: 30px;
  font-size: 15px;
  width: 240px;
  transition: all 1s ease;
  z-index: 1;
  position: relative;
}

.primary-buttons > button:before,
.primary-buttons > button:after {
  content: "";
  height: 240px;
  width: 240px;
  position: absolute;
  z-index: -1;
  top: 150%;
  left: 0px;
  transition: all 1.4s ease;
}

.primary-buttons > button:before {
  border-radius: 60% 70% 20% 40%;
  background: #d5fc48;
  opacity: 0.2;
  animation: water 13s ease-in-out infinite both alternate;
}

.primary-buttons > button:after {
  border-radius: 60% 70% 20% 40%;
  background: #d5fc48;
  opacity: 0.5;
  animation: water 14s ease-in-out infinite both alternate;
}

.primary-buttons > button:hover {
  background: #d5fc48;
  color: #00674b;
}

.primary-buttons > button:hover:before,
.primary-buttons > button:hover:after {
  opacity: 1;
  top: -10%;
}

@keyframes water {
  0% {
    transform: rotate(0);
  }
  20% {
    border-radius: 50% 63% 80% 49%;
  }
  40% {
    border-radius: 60% 64% 64% 48%;
  }
  60% {
    border-radius: 80% 63% 51% 49%;
  }
  80% {
    border-radius: 40% 60% 42% 58%;
  }
  100% {
    border-radius: 62% 67% 70% 53%;
    transform: rotate(180deg);
  }
}

.primary-buttons > button:hover {
  cursor: pointer;
}

.secondary-button {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-top: 40px;
}

.secondary-button > button {
  background: #f1f1f1;
  border: none;
  color: #00674b;
  padding: 13px 14px 13px 14px;
  width: 240px;
  font-family: "Be Vietnam Pro", sans-serif;
  border-radius: 30px;
  font-size: 15px;
  position: relative;
  z-index: 2;
  overflow: hidden;
  transition: all 1s ease;
  position: relative;
}

.secondary-button > button:before,
.secondary-button > button:after {
  content: "";
  height: 240px;
  width: 240px;
  position: absolute;
  z-index: -1;
  top: 150%;
  left: 0px;
  transition: all 1.4s ease;
}

.secondary-button > button:before {
  border-radius: 60% 70% 20% 40%;
  background: #00674b;
  opacity: 0.2;
  animation: water 13s ease-in-out infinite both alternate;
}

.secondary-button > button:after {
  border-radius: 60% 70% 20% 40%;
  background: #00674b;
  opacity: 0.5;
  animation: water 14s ease-in-out infinite both alternate;
}

.secondary-button > button:hover {
  background: #00674b;
  color: #f1f1f1;
}

.secondary-button > button:hover:before,
.secondary-button > button:hover:after {
  opacity: 1;
  top: -10%;
}

.secondary-button > button:hover {
  cursor: pointer;
}

@media (max-width: 600px) {
  .secondary-button {
    margin-top: 20px;
    gap: 10px;
  }
  .cta-details .secondary-button {
    justify-content: center;
  }
  .cta-details .secondary-button {
    margin-top: 50px;
  }
  .secondary-button > button {
    font-size: 10px;
    padding: 12px 20px 12px 20px;
    width: 150px;
  }
  .marquee-text {
    margin-right: 10px;
  }
  .primary-buttons {
    gap: 20px;
    margin-bottom: 35px;
  }
  .primary-buttons > button {
    width: 150px;
  }
}
