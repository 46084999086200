.footer-container {
  background: #eefdff;
  padding: 50px;
  margin-top: 300px;
  position: relative;
  height: 350px;
}

.footer-details {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.footer-main-text {
  margin-bottom: 40px;
  margin-top: 20px;
}

.footer-main-text > h5 {
  color: #0f4c54;
  font-family: "Be Vietnam Pro", sans-serif;
  font-size: 35px;
}

.footer-buttons {
  display: flex;
  gap: 40px;
  margin-bottom: 50px;
}

.footer-buttons > a {
  color: #fff;
  font-size: 18px;
  text-align: center;
  text-decoration: none;
  font-family: "Be Vietnam Pro", sans-serif;
  font-weight: 300;
  width: 27%;
  gap: 40px;
  background: #0f4c54;
  padding: 15px 35px 15px 35px;
  border-radius: 30px;
  z-index: 1;
  overflow: hidden;
  transition: all 1s ease;
  position: relative;
}

.footer-buttons > a:before,
.footer-buttons > a:after {
  content: "";
  height: 240px;
  width: 240px;
  position: absolute;
  z-index: -1;
  top: 150%;
  left: 0px;
  transition: all 1.4s ease;
}

.footer-buttons > a:before {
  border-radius: 60% 70% 20% 40%;
  background: #d5fc48;
  opacity: 0.2;
  animation: water 13s ease-in-out infinite both alternate;
}

.footer-buttons > a:after {
  border-radius: 60% 70% 20% 40%;
  background: #d5fc48;
  opacity: 0.5;
  animation: water 14s ease-in-out infinite both alternate;
}

.footer-buttons > a:hover {
  background: #d5fc48;
  color: #00674b;
}

.footer-icon {
  margin-right: 10px;
  font-size: 20px;
}

.footer-buttons > a:hover:before,
.footer-buttons > a:hover:after {
  opacity: 1;
  top: -10%;
}

@keyframes water {
  0% {
    transform: rotate(0);
  }
  20% {
    border-radius: 50% 63% 80% 49%;
  }
  40% {
    border-radius: 60% 64% 64% 48%;
  }
  60% {
    border-radius: 80% 63% 51% 49%;
  }
  80% {
    border-radius: 40% 60% 42% 58%;
  }
  100% {
    border-radius: 62% 67% 70% 53%;
    transform: rotate(180deg);
  }
}

.footer-socials-icon > h5 {
  color: #000;
  font-family: "Be Vietnam Pro", sans-serif;
  font-size: 17px;
  font-weight: 400;
}

.footer-twitter-icon {
  margin-top: 15px;
  margin-bottom: 15px;
  color: #0f4c54;
  font-size: 25px;
}

.footer-socials a {
  color: #000;
  font-family: "Be Vietnam Pro", sans-serif;
}

.footer-illustration > img {
  width: 700px;
  position: absolute;
  right: 10px;
  top: -250px;
}

@media (max-width: 600px) {
  .footer-container {
    padding: 0px;
    margin-top: 50px;
    height: 450px;
  }
  .footer-buttons {
    gap: 10px;
  }
  .footer-buttons > a {
    width: 150px;
    padding: 12px;
    font-size: 15px;
  }
  .footer-details {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 340px;
    padding-top: 100px;
  }
  .footer-illustration > img {
    width: 380px;
    top: -290px;
  }
  .footer-main-text > h5 {
    font-size: 25px;
    text-align: center;
  }
}
