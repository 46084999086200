.hero-page-container {
  padding: 40px;
  background: url("../../images/heropage-background.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  margin: 40px;
  position: relative;
  height: 650px;
  border-radius: 20px;
  z-index: 0;
}

.nav-details {
  margin-top: 20px;
}

.nav-logo > a > img {
  margin-top: -30px;
}

.nav-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32%;
  gap: 50px;
  background: #0f4c54;
  height: 55px;
  padding: 0px 15px 0px 15px;
  margin-top: -75px;
  margin-right: -30px;
  border-radius: 15px;
}

.nav-buttons > hr {
  height: 55px;
  width: 15px;
  background: #d5fc48;
  border: none;
}

.nav-main-button {
  color: #fff;
  font-size: 18px;
  text-decoration: none;
  font-family: "Be Vietnam Pro", sans-serif;
  font-weight: 300;
}

@keyframes slide-in {
  from {
    transform: translateX(-90px);
  }
  to {
    transform: translateX(0px);
  }
}

.nav-icon {
  font-size: 20px;
  margin-bottom: -3px;
}

.hero-page-image-1 {
  width: 550px;
  position: absolute;
  bottom: -159px;
  right: 0px;
}

.nav-mobile-button {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-right: 10px;
}

.nav-mobile-button > div {
  background: #eefdff;
  height: 45px;
  width: 45px;
  border-radius: 50%;
  color: #0f4c54;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-mobile-button > div > .mobile-icons {
  font-size: 25px;
}

.nav-mobile-button > div > .mobile-icons:nth-child(1) {
  font-size: 20px;
}

.hero-page-image-2 {
  width: 360px;
  position: absolute;
  left: 10px;
  z-index: 1;
  bottom: -190px;
}

@media (max-width: 600px) {
  .hero-page-container {
    width: 85%;
    height: 90vh;
    padding: 10px;
    margin: 20px;
  }
  .hero-page-image-1 {
    display: none;
  }
  .nav-buttons {
    display: none;
  }
  .hero-page-image-2 {
    width: 200px;
    bottom: -16px;
  }
  .nav-logo {
    margin-top: 25px;
    margin-left: 10px;
  }
  .nav-logo > a > img {
    width: 190px;
    margin-top: -10px;
  }
}

@media (min-width: 1200px) {
  .nav-mobile-button {
    display: none;
  }
}
